<template>
  <div v-if="isDevelop && show" class="banner">
    <v-expansion-panels v-model="panels" variant="accordion" elevation="0" class="mb-0">
      <v-expansion-panel class="controls-justify-left">
        <v-expansion-panel-title>
          <!-- header -->
          <div>
            <v-icon class="me-1">mdi mdi-code-tags</v-icon>
            <span class="panel-title">
              <slot name="title">Developer Mode Content</slot>
            </span>
          </div>
          <v-icon class="ms-auto" @click.stop="show = false">mdi mdi-close</v-icon>
        </v-expansion-panel-title>

        <!-- content -->
        <v-expansion-panel-text class="px-4 mx-6">
          <v-card class="mt-2">
            <v-card-text>
              <slot></slot>
            </v-card-text>
          </v-card>

          <!-- disclaimer -->
          <v-divider class="line mt-4 mb-1" />
          <div class="text-center opacity-60">
            <i><small>This section is visible ONLY to developers or in the 'Develop' stage.</small></i>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script lang="ts">
/**
 * base/DeveloperContainer
 * This component is responsible to render a content visible only in development mode (localhost/develop)
 * 
 * HOW TO USE:
      <develop-container :v-model="true">
        <template #default> This is some custom content inside the card. </template>
      </develop-container>
 */
import { defineComponent } from "vue";

export default defineComponent({
  name: "DeveloperContainer",
  setup() {
    return {};
  },
  props: {
    modelValue: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue"],
  // #region Properties
  data() {
    return {
      show: this.modelValue,

      // UI
      panels: [],
      panelStatus: false,
    };
  },
  computed: {
    isDevelop(): boolean {
      // Returns if the app is in development mode
      return false; // TODO: change to the following line
      // return import.meta.env.VITE_APP_DEVELOPMENT === "true";
    },
  },
  watch: {
    // Sync props
    modelValue(newValue: boolean) {
      this.show = newValue;
    },
    show(newValue: boolean) {
      this.$emit("update:modelValue", newValue);
    },
  },
});
</script>

<style scoped>
div.banner {
  background-color: rgba(255, 255, 204, 0.5);
  border-radius: 4px;
  padding: 16px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.v-expansion-panel {
  background-color: transparent;
}
span.panel-title {
  font-weight: bold;
}
</style>
