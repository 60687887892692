<template>
  <div>
    <div class="progress-bar">
      <v-progress-linear v-if="showLoading" color="primary" indeterminate />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoaderBase",
  computed: {
    showLoading(): boolean {
      return this.$globalsService.showLoading;
    },
  },
});
</script>
<style scoped>
.progress-bar {
  position: fixed;
  width: 100%;
  left: 0;
  top: 60px;
  z-index: 2000;
}
</style>
