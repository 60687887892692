<template>
  <div
    data-cy="file-upload"
    class="dragContainer"
    :class="{ 'video': canUploadByUrl }"
    @dragover.prevent="onDragOver()"
    @dragleave.prevent="onDragLeave()"
    @drop.prevent="onDrop($event)"
  >
    <div class="mt-2 mb-3">
      <v-icon size="xxx-large">mdi mdi-image-outline</v-icon>
    </div>
    <div>
      <span> Drop your {{ fileType }} here </span>
    </div>
    <div class="mt-1 mb-2">
      <span> Or </span>
    </div>
    <div>
      <v-btn :color="color" @click="triggerFile"> Upload a {{ fileType }} file </v-btn>
    </div>
    <div class="mt-2">
      <span class="opacity-60"> supports {{ acceptString }} </span>
    </div>
    <input v-if="allowInput" ref="fileControl" type="file" :accept="accept" class="d-none" @change="onSelectFile($event.target)" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { fileHelper } from "@/helpers/file";
import { useMessageBoxStore, MessageConfig } from "@/store/message-box";

export interface FileData {
  file: any;
  base64: string;
  extension: string;
  imageSize?: {
    width: number;
    height: number;
  };
}

export default defineComponent({
  name: "FileUploader",
  emits: ["onResize", "onUnexcpectedFormat", "onUnexcpectedFileSize", "onUploaded", "onUploading"],
  props: {
    title: {
      type: String,
      required: false,
      default() {
        return "Drag & Drop here your file";
      },
    },
    mode: {
      type: String,
      required: false,
      default() {
        return "all"; // onlyDragAndDrop|onlyInput|all
      },
    },
    dragTitle: {
      type: String,
      required: false,
      default() {
        return "Leave here your file";
      },
    },
    uploadTitle: {
      type: String,
      required: false,
      default() {
        return "Uploading...";
      },
    },
    fileType: {
      type: String,
      default() {
        return "image";
      },
    },
    canUploadByUrl: {
      type: Boolean,
    },
    maxFileSize: {
      type: Number,
      default: 512000000,
    },
    hasCropper: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default() {
        return "primary";
      },
    },
  },
  setup() {
    // refs
    const fileControl: any = ref("fileControl");
    const messageBox = useMessageBoxStore();

    window.stores = { messageBox };
    return {
      fileControl,
      messageBox,
    };
  },

  // #region Properties
  data() {
    return {
      drag_status: 0,
      accept: "",
      acceptString: "",
    };
  },
  mounted() {
    this.accept = fileHelper.getSupportedExtensions(this.fileType as "document" | "image" | "video");
    this.acceptString = this.accept.replaceAll(".", " ");
  },
  computed: {
    statusTitle() {
      const titles = [this.title, this.dragTitle, this.uploadTitle];

      return titles[this.drag_status];
    },
    allowInput() {
      // returns true if mode allow input file
      return this.mode.trim().toLowerCase() === "" || this.mode.trim().toLowerCase() === "all" || this.mode.trim().toLowerCase() === "onlyinput";
    },
    allowDragAndDrop() {
      // returns true if mode allow input file
      return (
        this.mode.trim().toLowerCase() === "" || this.mode.trim().toLowerCase() === "all" || this.mode.trim().toLowerCase() === "onlydraganddrop"
      );
    },
  },
  // #endregion Life Cycle

  // #region Watchers
  watch: {},
  // #endregion Properties

  methods: {
    // #region Drag & Drop Event Listners
    // eslint-disable-next-line no-unused-vars
    onDragOver() {
      if (!this.allowDragAndDrop) return;
      this.drag_status = 1;
    },
    // eslint-disable-next-line no-unused-vars
    onDragLeave() {
      if (!this.allowDragAndDrop) return;
      this.drag_status = 0;
    },
    onDrop(event: any) {
      if (!this.allowDragAndDrop) return;
      this.drag_status = 2;
      this.fileSelected(event.dataTransfer.files[0]); // gets only the 1st dropped file
    },
    // #endregion Drag & Drop Event Listners

    // #region Input[file] Event Listners
    onSelectFile(target: any) {
      // gets only the 1st dropped file
      this.fileSelected(target.files[0]); // gets only the 1st dropped file
    },
    triggerFile() {
      this.fileControl.click();
    },
    // #endregion Input[file] Event Listners

    // #region Utils
    fileSelected(file: any) {
      // incoming file from drag&drop or selection
      this.fileControl.value = null; // alway resets input field
      const fileData = {
        file: file,
        extension: file.name ? file.name.substring(file.name.lastIndexOf(".")) : "noext",
      } as FileData;
      // Checks if file has correct extension
      if (this.accept.indexOf(fileData.extension) < 0) {
        // Wrong file type
        this.drag_status = 0;
        this.fileFormatMessage(file.type);
      } else if (file.size > this.maxFileSize) {
        this.drag_status = 0;
        this.maxFileSizeMessage();
      } else {
        this.$emit("onUploading");
        //convert to base64
        const self = this;
        const reader = new FileReader();
        reader.onload = (f: any): void => {
          fileData.base64 = f.target.result; // f.target.result contains the base64 encoding of the image
          if (this.fileType === "image") {
            const img = new Image(); // loads the image to get (and integrate) its size

            img.addEventListener("load", function () {
              fileData.imageSize = {
                width: this.width,
                height: this.height,
              };
              self.raise_onUpload(fileData); // ATTENTIONS: here we were using self instead of this. sends file meta-data and its data
              self.drag_status = 0; // ATTENTIONS: here we were using self instead of this.
            });
            img.src = f.target.result.toString();
          } else {
            self.$emit("onUploaded", fileData);
            self.drag_status = 0; // ATTENTIONS: here we were using self instead of this.
          }
        };
        reader.readAsDataURL(file);
      }
    },
    // #endregion Utils

    // #region Events
    async raise_onUpload(fileData: FileData) {
      // checks if we have to resize to maxSize
      const cropperMaxSiz = 480;
      if (this.hasCropper && fileData.imageSize && (fileData.imageSize.width > cropperMaxSiz || fileData.imageSize.height > cropperMaxSiz)) {
        fileData.base64.resizeBase64(cropperMaxSiz).then((result: any) => {
          fileData.base64 = result.data;
          fileData.imageSize = {
            width: result.width,
            height: result.height,
          };
          this.$emit("onResize", fileData); // notify auto-resize
          this.$emit("onUploaded", fileData);
        });
      } else {
        this.$emit("onUploaded", fileData);
      }
    },
    maxFileSizeMessage() {
      const msg: MessageConfig = {
        title: "Warning",
        message: "You have uploaded a file larger than 512 MB size limit. Please try again with a smaller file.",
        icon: "mdi mdi-alert-box",
        buttons: [
          {
            key: "ok",
            text: "Close",
            button: true,
          },
        ],
      };

      this.messageBox.show(msg);
    },
    fileFormatMessage(fileType: string) {
      const msg: MessageConfig = {
        title: "Warning",
        message: `Wrong file format. Please, select an ${fileType} file!`,
        icon: "mdi mdi-alert-box",
        buttons: [
          {
            key: "ok",
            text: "Close",
            button: true,
          },
        ],
      };

      this.messageBox.show(msg);
    },
    // #region Events
  },
});
</script>

<style scoped lang="scss">
.dragContainer {
  width: 100%;
  text-align: center;
  border-style: dotted;
  border-width: 1px;
  padding: 30px;
  &.video {
    height: calc(100% - 100px);
  }
  &:not(.video) {
    height: 100%;
  }
  i {
    opacity: 0.5;
  }
}
</style>
