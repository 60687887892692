<template>
  <v-hover v-slot="{ isHovering, props }">
    <v-card
      v-if="!onlyUploader"
      :data-cy="dataCy"
      v-bind="props"
      elevation="0"
      style="overflow: hidden"
      class="cursor-pointer w-content"
      @click="showDialog"
    >
      <slot>
        <div v-if="fileType === 'image'">
          <div>
            <img
              v-if="logo"
              :src="logo"
              :style="height ? `height:${height}px;width: auto;` : ''"
              :class="`imglogo pa-3 ${imageClass}`"
              :alt="title"
            />
            <div v-else class="pa-4">
              <span class="title">{{ title }}</span>
            </div>
          </div>
          <v-overlay v-if="enabled" :model-value="isHovering as boolean" contained scrim="#dedede" class="align-center justify-center">
            <v-btn variant="text" color="primary" size="x-large" class="align-self-center" :icon="$icons.edit" />
          </v-overlay>
        </div>
        <div v-if="fileType === 'document'" class="w-content">
          <v-btn :prepend-icon="$icons.upload_cloud" color="primary" :disabled="!$globalsService.hasWorkspacePrivileges(['file_add'])">
            Upload document
          </v-btn>
          <v-icon v-if="logo" color="primary">{{ $icons.true }}</v-icon>
        </div>
        <div v-if="fileType === 'video'" class="w-content">
          <v-btn :prepend-icon="$icons.upload_cloud" color="primary" :disabled="!$globalsService.hasWorkspacePrivileges(['file_add'])">
            Upload a Video
          </v-btn>
          <v-icon v-if="logo" color="primary">{{ $icons.true }}</v-icon>
        </div>
      </slot>
    </v-card>
  </v-hover>
  <v-dialog v-model="display" :width="fullScreen ? undefined : 500" persistent :fullscreen="fullScreen" class="dialog">
    <v-card class="w-100 h-100">
      <!-- icon + title + cancel button -->
      <v-card-title>
        <div class="d-inline mt-2">
          <v-icon class="mr-2">{{ icon }}</v-icon>
          <span>{{ title }}</span>
        </div>
        <v-spacer />
        <v-btn
          v-if="!$vuetify.display.xs"
          :disabled="isLoading"
          variant="text"
          :icon="fullScreen ? $icons.collapse : $icons.expand"
          @click.stop="fullScreen = !fullScreen"
        />
        <v-btn :disabled="isLoading" variant="text" :icon="$icons.close" @click.stop="close()" />
      </v-card-title>
      <TabsBase v-if="archiveTags && withArchive && step === 'start'" v-model="tab" :tabs="tabs" class="mb-n3" />

      <v-card-text class="mt-2 px-2">
        <v-window v-model="tab" class="h-100">
          <!-- File Upload step -->
          <v-window-item value="upload" class="h-100">
            <div v-show="step === 'start'" class="h-100">
              <FileUploader
                width="100%"
                :can-upload-by-url="canUploadByUrl"
                mode="all"
                :title="`Drag & drop your ${fileType} here, or click to upload`"
                drag-title="Leave your image here"
                upload-title="Uploading image..."
                :fileType="fileType"
                :multiple="multipleFile"
                :maxFiles="maxFiles"
                @on-uploading="isLoading = true"
                @on-uploaded="onFileUpload($event)"
              />
              <template v-if="canUploadByUrl">
                <div class="w-100 text-center my-3">
                  <span class="subtitle opaque">Or upload via url</span>
                </div>
                <v-row>
                  <v-col>
                    <v-text-field v-model="videoLink" data-cy="video-Link" label="Video Url" variant="outlined" density="compact" />
                  </v-col>
                  <v-col cols="auto">
                    <v-btn color="primary" @click="uploadVideoByUrl">Upload </v-btn>
                  </v-col>
                </v-row>
              </template>
            </div>
            <div v-if="step === 'cropper'">
              <CropperComponent ref="vcropper" :image="fileData.base64" :is-circle="isCircle" :aspect-ratio="aspectRatio" />
            </div>
            <div v-if="step === 'preview'" class="text-center">
              <!-- image preview -->
              <template v-if="fileType === 'image'">
                <img :src="fileData.base64" />
              </template>
              <template v-if="fileType === 'video' && videoSource">
                <!-- video preview player -->
                <video class="video-preview" controls autoplay muted>
                  <source :src="videoSource" />
                </video>
              </template>
            </div>
          </v-window-item>

          <!-- Media step -->
          <v-window-item value="media" class="h-100">
            <template v-if="filesLoading"> </template>
            <template v-else>
              <v-row v-if="files.length > 0">
                <v-col :cols="$vuetify.display.xs ? 12 : 6" v-for="file in files" :key="file.id" class="pa-3" @click="selectArchiveFile(file)">
                  <div class="file-card" :class="{ 'active': file === selectedArchiveFile }">
                    <div class="img-content">
                      <div class="img" v-if="fileType === 'image'">
                        <img :src="file.url" :alt="file.name" />
                      </div>
                      <template v-if="fileType === 'document'">
                        <div class="d-content">
                          <img class="file-icon" :src="getIconByExtension(file.name)" :alt="file.name" />
                          <v-spacer />
                          <v-btn variant="text" @click.stop="openInNewTab(file.url)" color="primary" class="view"> View </v-btn>
                        </div>
                      </template>
                      <template v-if="fileType === 'video'">
                        <video class="video-icon">
                          <source :src="file.url" />
                        </video>
                      </template>
                    </div>
                    <div>
                      <span class="d-block text-truncate-ellipsis">Name: {{ file.name }}</span>
                      <span class="d-block">Extension: {{ file.extension }}</span>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <template v-else>
                <span class="subtitle opaque text-uppercase">No files present</span>
              </template>
            </template>
          </v-window-item>
        </v-window>
      </v-card-text>
      <v-divider />

      <!-- Upload step -->
      <v-card-actions>
        <template v-if="tab === 'upload'">
          <!-- save part -->
          <v-row>
            <v-col class="py-0">
              <v-progress-linear v-if="isLoading" class="mt-1" height="25" indeterminate color="primary"></v-progress-linear>
            </v-col>
            <v-col cols="auto" class="py-0">
              <!-- Back button isn't really needed, since we haven't a lot of space here and there's an X button on the titlebar -->
              <v-btn v-if="step !== 'start' && false" :disabled="isLoading" data-cy="image-back" color="secondary" @click="back()">Back </v-btn>
              <v-btn v-if="step === 'cropper'" :disabled="isLoading" data-cy="image-crop" color="primary" @click="cropImage()"
                >{{ withPreview ? "Next" : "Save" }}
              </v-btn>
              <v-btn v-if="step === 'preview'" :disabled="isLoading" data-cy="image-save" color="primary" @click="save()">Save </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-btn data-cy="image-archive-save" :disabled="!selectedArchiveFile" color="primary" @click="saveByArchive()">Save </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
//
import { defineComponent, inject } from "vue";
import { fileHelper } from "@/helpers/file";

// Components
import CropperComponent from "@/components/tools/CropperComponent.vue";
import FileUploader from "@/components/tools/FileUploader.vue";
import { FileData } from "@/components/tools/FileUploader.vue";

import {
  ConduxApiResearcherV1FilesGetRequest,
  ConduxApiResearcherV1FilePostBody,
  ConduxApiResearcherV1FileUploadUrlGetRequest,
  File,
} from "@conduxio/types";

import { FileService } from "@/services/file.service";

export default defineComponent({
  name: "FileManager",
  components: {
    FileUploader,
    CropperComponent,
  },
  props: {
    modelValue: {
      type: String,
    },
    imageClass: {
      type: String,
      default: "",
    },
    title: {
      type: String,
    },
    icon: {
      type: String,
      default: "mdi-cloud-upload",
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    height: {
      type: Number,
    },
    isCircle: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: Number,
    },
    maxSize: {
      type: Number,
    },
    dataCy: {
      type: String,
    },
    archiveTags: {
      type: Array<string>,
    },
    fileType: {
      type: String,
    },
    withCropper: {
      type: Boolean,
      default: true,
    },
    withArchive: {
      type: Boolean,
      default: true,
    },
    onlyUploader: {
      type: Boolean,
    },
    saveToMedia: {
      type: Boolean,
      default: true,
    },
    multipleFile: {
      type: Boolean,
    },
    maxFiles: {
      type: Number,
      default: -1,
    },
    withPreview: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue", "error", "uploaded"],
  setup() {
    const fileService = inject<FileService>("fileService") as FileService;

    return {
      fileService,
    };
  },

  // #region Properties
  data() {
    return {
      display: false,
      maxFileSize: 9000000, //max aws file size is 1M
      bigFileSaveMode: false,
      fullScreen: false,
      step: "start" as "start" | "cropper" | "preview",
      fileData: {
        file: null,
        base64: "",
        extension: "",
      } as FileData,
      fileBeforeCrop: {
        file: null,
        base64: "",
        extension: "",
      } as FileData,
      isLoading: false,
      logo: this.modelValue,
      tab: "upload",
      tabs: [
        { label: "Upload", value: "upload", icon: this.$icons.upload },
        { label: "Media", value: "media", icon: this.$icons.media },
      ],
      files: [] as File[],
      filesLoading: true,
      selectedArchiveFile: undefined as File | undefined,
      videoLink: "",
      videoSource: null as string | null,
    };
  },
  computed: {
    canUploadByUrl(): boolean {
      return false;
      // return this.fileType === "video";    // We don't really want to provide this feature right now
    },
  },
  watch: {
    modelValue: function (newVal) {
      this.logo = newVal;
    },
    tab: function (newVal) {
      if (newVal === "media") {
        this.getArchivieFiles();
      }
    },
  },
  // #endregion Properties

  // #region Methods
  methods: {
    showDialog() {
      this.display = true;
    },
    getArchivieFiles(): void {
      this.filesLoading = true;
      const request = { fileType: this.fileType, tags: this.archiveTags } as ConduxApiResearcherV1FilesGetRequest;

      this.fileService.readAll(request).then((response) => {
        if (response && response.status === "OK") {
          this.files = response.files;
        } else {
          this.$notification.error(response.errorMessage as string);
        }
        this.filesLoading = false;
      });
    },
    onFileUpload(fileData: FileData): void {
      this.fileData = fileData;
      this.isLoading = false;
      if (this.fileType === "image") {
        if (this.withCropper && this.aspectRatio) {
          this.step = "cropper";
        } else {
          if (this.withPreview) {
            this.step = "preview";
          } else {
            this.save();
          }
        }
      } else if (this.fileType === "video") {
        if (this.withPreview) {
          this.step = "preview";
          this.videoSource = URL.createObjectURL(fileData.file);
        } else {
          this.save();
        }
      } else {
        this.save();
      }
    },
    uploadVideoByUrl() {
      this.logo = this.videoLink;
      this.$emit("update:modelValue", this.logo);
      this.close();
      this.$emit("uploaded");
    },
    selectArchiveFile(file: File) {
      this.selectedArchiveFile = file;
    },
    cropImage(): void {
      const canvas = (this.$refs.vcropper as any).getCropImage();
      this.fileBeforeCrop = this.$utilities.deepClone(this.fileData);
      this.fileData.base64 = canvas.toDataURL();
      this.fileData.imageSize = {
        height: canvas.height,
        width: canvas.width,
      };

      if (this.maxSize && (canvas.width > this.maxSize || canvas.height > this.maxSize)) {
        this.fileData.base64.resizeBase64(this.maxSize).then((result: any) => {
          this.fileData.base64 = result.data;
          this.fileData.imageSize = {
            height: result.height,
            width: result.width,
          };
        });
      }
      if (this.withPreview) {
        this.step = "preview";
      } else {
        this.save();
      }
    },
    save(): void {
      // Saves data
      if (this.fileData.base64.length > this.maxFileSize) {
        this.saveBigFile();

        return;
      }
      this.isLoading = true;
      const request = {
        file: {
          base64Data: this.fileData.base64,
          name: this.fileData.file.name,
          type: this.fileType,
          height: this.fileType === "image" && this.fileData.imageSize ? this.fileData.imageSize.height : undefined,
          width: this.fileType === "image" && this.fileData.imageSize ? this.fileData.imageSize.width : undefined,
          tags: this.archiveTags,
          saveToMedia: this.saveToMedia,
        },
      } as ConduxApiResearcherV1FilePostBody;

      this.fileService.create(request).then((response) => {
        if (response && response.status === "OK") {
          this.logo = response.file.url;
          this.$emit("update:modelValue", this.logo);
          this.close();
          this.$emit("uploaded");
        } else {
          this.$notification.error(response.errorMessage as string);
        }
        this.isLoading = false;
      });
    },
    saveByArchive() {
      if (this.selectedArchiveFile) {
        this.logo = this.selectedArchiveFile.url;
        this.$emit("update:modelValue", this.logo);
        this.close();
        this.$emit("uploaded");
      }
    },
    async saveBigFile() {
      this.isLoading = true;

      console.log(this.$globalsService.getWorkspaceId());

      try {
        const request = {
          fileExtension: fileHelper.getExtension(this.fileData.file.name).substring(1),
          path: this.$globalsService.getWorkspaceId(),
        } as ConduxApiResearcherV1FileUploadUrlGetRequest;

        await this.fileService.getUploadUrl(request).then(async (response) => {
          if (response && response.status === "OK") {
            await this.fileService.uploadFile(this.fileData.file, response.url).then(async (response2) => {
              if (response2 && response2.status === "OK") {
                this.logo = response.url;
                this.$emit("update:modelValue", this.logo);
                if (this.saveToMedia) {
                  const request = {
                    file: {
                      fileUrl: response.url,
                      name: this.fileData.file.name,
                      type: this.fileType,
                      height: this.fileType === "image" && this.fileData.imageSize ? this.fileData.imageSize.height : undefined,
                      width: this.fileType === "image" && this.fileData.imageSize ? this.fileData.imageSize.width : undefined,
                      tags: this.archiveTags,
                      saveToMedia: true,
                    },
                  } as ConduxApiResearcherV1FilePostBody;

                  await this.fileService.create(request).then((response) => {
                    if (!response || response.status !== "OK") {
                      this.$notification.error(response.errorMessage as string);
                    }
                  });
                }
                this.close();
                this.$emit("uploaded");
              } else {
                this.$notification.error(response2.errorMessage as string);
              }
            });
          } else {
            this.$notification.error(response.errorMessage as string);
          }
        });
      } catch (e) {
        console.log(e);
        this.$notification.error("Something wrong");
      } finally {
        this.isLoading = false;
      }
    },
    back() {
      if (this.step === "cropper") {
        this.clearControls();
      } else if (this.step === "preview") {
        if (this.fileType === "image" && this.withCropper && this.aspectRatio) {
          this.$utilities.deepCloneWithRef(this.fileBeforeCrop, this.fileData);
          this.step = "cropper";
        } else {
          this.clearControls();
        }
      }
    },
    close(): void {
      this.clearControls();
      this.display = false;
    },
    clearControls() {
      this.bigFileSaveMode = false;
      this.isLoading = false;
      this.fileData = {
        file: null,
        base64: "",
        extension: "",
      };
      this.selectedArchiveFile = undefined;
      this.tab = "upload";
      this.videoLink = "";
      this.step = "start";
    },
    openInNewTab(url) {
      this.$utilities.openInNewWindow(url);
    },
    getIconByExtension(filename: string): string {
      return fileHelper.getIconByExtension(filename);
    },
  },
  // #endregion Methods
});
</script>

<style scoped lang="scss">
.imglogo {
  width: 100%;
}
.file-card {
  width: 100%;
  height: 200px;
  padding: 5px;
  border: 1px solid #c6c6c6;
  cursor: pointer;
  .img-content {
    height: 155px;
    display: flex;
    &:has(.img) {
      justify-content: center;
      align-items: center;
    }
  }
  .img {
    display: contents;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
  span {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover:not(:has(.view:hover)) {
    border-bottom: 3px solid var(--color-dark_gray) !important;
    padding-bottom: 3px !important;
  }
  &.active {
    border-bottom: 3px solid var(--color-green) !important;
    padding-bottom: 3px !important;
  }
  .file-icon {
    height: 100%;
  }
  .video-icon {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
  }
}
.video-preview {
  max-width: 100%;
  width: 100%;
  max-height: 440px;
}
</style>
