<template>
  <v-row no-gutters>
    <v-col v-for="(pb, index) in progressBars" :key="index" cols="3" class="px-1">
      <div style="position: relative; width: 100%">
        <v-progress-linear
          :model-value="getProgressValueInPercentage(pb)"
          :location="null"
          min="0"
          max="100"
          rounded
          height="12"
          :bg-color="`#${pb.color}`"
          :color="`#${pb.color}`"
        ></v-progress-linear>
        <div
          v-if="isDotVisible(pb)"
          :style="{ ...dotStyle, left: `calc(${getProgressValueInPercentage(pb)}% - 4px)`, borderColor: `#${pb.color}` }"
        />
      </div>
    </v-col>
  </v-row>

  <template v-if="demo">
    <v-slider v-if="demo" v-model="localValue" class="mt-4" :min="0" :max="100" :step="1" show-ticks="always"></v-slider>
    <div class="text-center">{{ localValue }}%</div>
  </template>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "SegmentedProgressBar",

  // #region Properties
  props: {
    progressBars: {
      type: Array as PropType<Array<{ min: number; max: number; color: string }>>,
      required: true,
    },
    value: {
      type: Number,
      required: true,
      validator: (val: number) => val >= 0 && val <= 100,
      default: 0,
    },
  },
  emits: ["update:value"],
  model: {
    prop: "value",
    event: "input",
  },
  data() {
    return {
      demo: false, // true to show the demo slider
      localValue: this.value,
    };
  },
  computed: {
    dotStyle() {
      return {
        position: "absolute",
        top: "50%",
        left: `0`, // calcualted:    calc(${this.progress}% - 10px)
        transform: "translateY(-50%)",
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        border: "4px solid #000000", // border-color calculated
        backgroundColor: "#ffffff",
        zIndex: 1,
      };
    },
  },
  // #endregion Properties

  methods: {
    /**
     * Returns the progress value in percentage
     * @param pb the progress bar
     */
    getProgressValueInPercentage(pb): number {
      if (this.localValue >= pb.max) return 100;
      if (this.localValue < pb.max && this.localValue > pb.min) {
        const percentage = ((this.localValue - pb.min) / (pb.max - pb.min)) * 100;
        return Math.max(0, Math.min(percentage, 100));
      }
      return 0;
    },
    /**
     * Returns true if we have to show the dot
     * @param pb the progress bar
     */
    isDotVisible(pb): boolean {
      if (this.localValue >= pb.min && this.localValue <= pb.max) return true;
      return false;
    },
  },

  watch: {
    // Sincronizza il valore locale se la props cambia
    value(newValue) {
      this.localValue = newValue;
    },
    // Emette un evento quando il valore locale cambia
    localValue(newValue) {
      this.$emit("update:value", newValue);
    },
  },
});
</script>

<style scoped>
.mb-4 {
  margin-bottom: 16px;
}
</style>
