/**
 * surveyAI.service.ts
 */

import { ConduxService } from "@/services/condux.service";
import {
  ConduxApiResearcherV1SurveyAIAnalysisExpertScoreGetResponse,
  ConduxApiResearcherV1SurveyAIAnalysisTimeEstimatesGetResponse,
} from "@conduxio/types";
export class SurveyAIService extends ConduxService {
  protected modelNameSingular = "survey";
  protected modelNamePlural = "surveys";

  public getTimeEstimates(surveyStudyID: string): Promise<ConduxApiResearcherV1SurveyAIAnalysisTimeEstimatesGetResponse> {
    // researcher/v1/study-v2/survey/{id}/ai-analysis/time-estimates
    const url = `study-v2/survey/${surveyStudyID}/ai-analysis/time-estimates`;
    const apiRequest = { url: url, withoutWorkspaceId: false, workspaceId: "", animate: false };

    return super.get<ConduxApiResearcherV1SurveyAIAnalysisTimeEstimatesGetResponse>(apiRequest);
  }
  public getExpertScore(surveyStudyID: string, lastTimestamp: number): Promise<ConduxApiResearcherV1SurveyAIAnalysisExpertScoreGetResponse> {
    // researcher/v1/study-v2/survey/{id}/ai-analysis/expert-score
    const url = `study-v2/survey/${surveyStudyID}/ai-analysis/expert-score`;
    const apiRequest = { url: url, withoutWorkspaceId: false, workspaceId: "", animate: false, request: { ts: lastTimestamp } };

    return super.get<ConduxApiResearcherV1SurveyAIAnalysisExpertScoreGetResponse>(apiRequest);
  }

  /**
   * Returns a label for the expert score
   * - 0-25%: Bad
   * - 26-50%: Fair
   * - 51-75%: Good
   * - 76-100%: Very Good
   * @param score a percentage value between 0 and 100
   */
  public getExpertScoreLabel(score: number): string {
    if (score >= 0 && score <= 25) {
      return "Bad";
    } else if (score >= 26 && score <= 50) {
      return "Fair";
    } else if (score >= 51 && score <= 75) {
      return "Good";
    } else if (score >= 76 && score <= 100) {
      return "Very Good";
    } else {
      throw new Error("Score must be between 0 and 100");
    }
  }
}
