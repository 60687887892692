<template>
  <div>
    <!--  
    fixed-tabs is for centering the tab horizontally
    stacked for spacing for icons
    grow get padding on tab for center
    properties:
    label
    value
    icon
    hide
    disabled
    -->
    <v-tabs
      v-model="tab"
      :grow="grow && $vuetify.display.mdAndUp"
      :fixed-tabs="$vuetify.display.smAndDown"
      :stacked="true"
      class="tabs-base c-indipendence text-none"
      :class="cssClass"
    >
      <template v-for="item in tabs" :key="item.value">
        <v-tab
          v-if="!item.hide"
          :disabled="item.disabled || $globalsService.showLoading"
          :value="item.value"
          color="primary"
          :data-cy="`tab-${item.value}`"
        >
          <v-icon v-if="item.icon" class="mb-1">{{ item.icon }}</v-icon>
          <span v-if="!item.icon || $vuetify.display.mdAndUp">{{ item.label }}</span>
        </v-tab>
      </template>
    </v-tabs>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
export interface Props {
  size: "default" | "small";
}

export default defineComponent({
  name: "TabsBase",
  props: {
    tabs: {
      type: Array as PropType<Array<any>>,
    },
    modelValue: {
      type: String,
    },
    size: {
      type: String as PropType<"default" | "small">,
    },
    grow: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["load", "update:modelValue"],

  data() {
    return {
      tab: this.modelValue,
      cssClass: "",
    };
  },
  watch: {
    tab: function (newVal) {
      this.$emit("update:modelValue", newVal);
    },
    size: {
      immediate: true,
      handler(val) {
        if (val === "small") {
          this.cssClass = "custom-small-tabs";
        }
      },
    },
  },
});
</script>

<style lang="scss">
div[role="tablist"] {
  height: auto;
}
@media (max-width: 400px) {
  .tabs-bases .v-btn__content:not(.v-icon) {
    font-size: 10px;
  }
  .tabs-base .v-btn__content .v-icon {
    font-size: 20px !important;
  }
}
@media (max-width: 700px) {
  .tabs-base .v-btn {
    min-width: auto !important;
  }
}

.custom-small-tabs {
  .v-btn {
    height: 30px !important;
    .v-btn__content {
      span {
        text-transform: capitalize;
        font-weight: bold;
        font-size: 13px;
      }
    }
  }
}
@media (max-width: 400px) {
  .custom-small-tabs {
    .v-btn {
      .v-btn__content {
        span {
          font-size: 11px;
        }
      }
    }
  }
}
</style>
