import { ConduxService } from "@/services/condux.service";
import { ConduxApiCommonV1Response } from "@conduxio/types";

export class SurveyJobService extends ConduxService {
  protected modelNameSingular = "study-v2/survey-job";
  // protected modelNamePlural = "studies-v2";

  public delete(id: string, studyId: string, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiCommonV1Response> {
    // const apiRequest = { url: this.modelNameSingular + "/" + id, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId, modelId: id };

    const apiRequest = {
      url: this.modelNameSingular + "/" + id,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      modelId: id,
      request: { study_id: studyId },
    };

    const ret = super.delete<ConduxApiCommonV1Response>(apiRequest);

    return ret;
  }
}
